import { Button, Chip, Grid, Paper, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { FormProvider, RHFRadioGroup, RHFSelectField, RHFTextField } from "../../../common/hook-form"
import Loader from "../../../common/Loader"
import { useDispatch, useSelector } from 'react-redux';
import { formatCurrency, getTotalUnitsOfOrder, maskAddress, parseDateAndTime, shouldMaskDataPD } from "../../../helpers/UtilityHelper"
import withLayout from "../../../hocs/Layout"
import { useLazyGetPaginatedShipmentsQuery, useLazyGetShipmentDetailQuery } from "../../../redux/commonRTK"
import { syncOrder } from '../../../redux/actions/orderList/orderListAction';
import { exclude, ItemListModal } from './index';
// import { Link } from 'react-router-dom'
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { Link } from 'react-router-dom'
import { OrderStatusWiseFilter } from "./index";
import PageTitle from "../../../common/PageTitle"
import MUIModal from "../../../common/MUIModal"
import QcDetails from "./QcDetails"
import { LoadingButton } from "@mui/lab"
import { apiConstants, postGeneric } from "../../../common/constants"
import { useSnackbar } from "notistack"
import _ from 'lodash'
import { addDays, format } from "date-fns"
import { useGetCouriersQuery } from "../../../redux/commonRTKPersist"
import { getMerchant } from "../../../helpers/ReduxHelpers"

const excludes = exclude + ",customerDetail";


function ReverseOrders() {
    // const { merchant } = useSelector((state) => state.user.userInfo);
    const merchant = getMerchant();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0)
    const [itemModalData, setItemModalData] = useState();
    const [itemModal, setItemModal] = useState(false);
    const [andFilters, setAndFilters] = useState([]);
    const [isLoadingResync, setisLoadingResync] = useState(false);
    const [newCourier, setNewCourier] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [clone, setClone] = useState(false);
    const [id, setId] = useState();
    const [courierData, setCourierData] = useState()
    const paymentMode = [{ label: "PREPAID", value: "PREPAID" }, { label: "COD", value: "COD" }]
    const [getShipmentDetail] = useLazyGetShipmentDetailQuery();
    const [resData, setResData] = useState([])
    const [isLoadingC, setIsLoadingC] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const { data: allCouriers } = useGetCouriersQuery()

    // const orFilter = [];
    const [getPaginatedShipments, { data, isLoading, isFetching }] = useLazyGetPaginatedShipmentsQuery()

    const methods = useForm({
        defaultValues: {
            reference: '',
            pickup_pincode: '',
            drop_pincode: '',
            awb: '',
            status: ''
        }
    });
    const {
        handleSubmit,
        reset,
    } = methods;

    const methods2 = useForm({
        defaultValues: {
            payment_mode: '',
            courier_type: ''
        }
    });
    const {
        handleSubmit: submitClone,
        reset: resetClone,
        watch: watchClone,
        setValue: setValClone
    } = methods2;

    const defaultFilters = [
        // { field: "is_reverse", operator: "eq", value: true },
        { field: "orderSubStatus", operator: "ne", value: "UNPROCESSED" },
        { field: "orderType", operator: "eq", value: "REVERSE SHIPMENT" },
        { field: "shipmentType", operator: "eq", value: "B2C" },
        { field: "merchant.$id", operator: "eq", value: merchant?.id }
    ]

    useEffect(() => {
        if (data?.count && data?.data) {
            setTotalRows(data.count)
        }
    }, [data])

    useEffect(() => {
        if (id && clone) {
            getShipmentDetail({
                "excludes": "sla,mongoLabelSignedUrlDetails,orderManifest,courierPricing,mongoInvoiceSignedUrlDetails,courierDetail,courier,courierId,merchant,merchantPricing,order_history", andfilter: [{ "field": "awbNumber", "operator": "eq", "value": id }], "offset": 0, "limit": 1
            }).then((res) => {
                let data = res.data.data[0]
                setResData(data)
                setValClone("payment_mode", data.payment_mode)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, clone])


    useEffect(() => {
        setPage(0)
    }, [sizePerPage])

    useEffect(() => {
        getPaginatedShipments({
            offset: (page * sizePerPage),
            limit: sizePerPage,
            andfilter: [...defaultFilters, ...andFilters],
            excludes,
            "sort": { "field": "awbRegisteredDate", "direction": "DESC" },
        })
        //dispatch(getOrderLists({ "excludes": exclude, "andfilter": [...defaultFilters, ...andFilters], "orfilter": orFilter, "offset": (page * sizePerPage), "limit": sizePerPage, "skipCount": true }));
        // eslint-disable-next-line
    }, [page, andFilters])

    const courierSelection = watchClone('courier_type')

    const applyFilters = (filters) => {
        let appliedFilters = [];
        if (filters.status) {
            appliedFilters.push({ "field": "orderStatus", "operator": "in", "value": [filters.status] })
        }
        if (filters.reference) {
            appliedFilters.push({ "field": "reference", "operator": "eq", "value": filters.reference })
        }

        if (filters.pickup_pincode) {
            appliedFilters.push({ "field": "pickupDetails.from_pincode", "operator": "eq", "value": filters.pickup_pincode })
        }

        if (filters.drop_pincode) {
            appliedFilters.push({ "field": "deliveryDetails.to_pincode", "operator": "eq", "value": filters.drop_pincode })
        }
        if (filters.awb) {
            appliedFilters.push({ "field": "awbNumber", "operator": "eq", "value": filters.awb })
        }

        setAndFilters(appliedFilters)
    }


    const handleSync = (awbNumber, reference) => {
        if (awbNumber) {
            setisLoadingResync(true)
            dispatch(syncOrder({ waybill: awbNumber, reference: reference })).finally(() => setisLoadingResync(false))
            // console.log(orderListInfo);
        }
    }
    const { report } = useSelector(state => state);

    const handleClickClone = (row, data) => {
        setCourierData(data)
        setClone(true);
        setId(row);
    };

    const columns = [
        {
            field: 'actions', headerAlign: "center", hideSortIcons: true, headerName: 'Order Details', flex: 1.3, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row.reference}
                            </Grid>
                            {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                                <Grid item xs={12}>
                                    {row.shippingLabelId}
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                {(row.createdDate) ? parseDateAndTime(row.createdDate) : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'courier', headerAlign: "center", hideSortIcons: true, headerName: 'Courier Partner', flex: 1.1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.awb_number) ? row.awb_number : ""}<br />
                            </Grid>
                            <Grid item xs={12}>
                                {row?.courierDetail?.parent}<br />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'delivery', headerAlign: "center", hideSortIcons: true, headerName: 'Delivery Details', flex: 1.3, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <div className="col-12 col-md position-relative mt-4 mt-md-0">
                            <div className="row g-0">
                                <div className="col-6 col-md-12 d-flex delivery-location flex-column flex-md-row">
                                    <div className="flex-shrink-0"> <img src="img/location.svg" height="17" alt="" /> </div>
                                    <div className="flex-grow-1 ms-md-3 mt-2 mt-md-0">{(row.pickup_details && row.pickup_details.from_name) ? row.pickup_details.from_name : "-NA-"}.<br />
                                        {/* {(row.pickup_details && row.pickup_details.from_pincode) ?
                                            row.pickup_details.from_pincode :
                                            "-NA-"} */}
                                        {(row.pickup_details && row.pickup_details.from_pincode) ? (shouldMaskDataPD(report, row) ?
                                            maskAddress(row.pickup_details.from_pincode) : row.pickup_details.from_pincode) : "-NA-"}
                                    </div>
                                </div>
                                <div className="col-6 col-md-12 d-flex delivery-location flex-column flex-md-row">
                                    <div className="flex-shrink-0 text-end text-md-start"> <img src="img/location.svg" height="17" alt="" /> </div>
                                    <div className="flex-grow-1 ms-md-3 d-flex justify-content-end justify-content-md-start mt-2 mt-md-0">{(row.delivery_details && row.delivery_details.to_name) ? row.delivery_details.to_name : ""}<br />
                                        {(row.delivery_details && row.delivery_details.to_pincode) ? row.delivery_details.to_pincode : "-NA-"}</div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            },
        },
        {
            field: 'shipment', headerAlign: "center", hideSortIcons: true, headerName: 'Package Details', flex: 1.3, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                Dead Weight : {(row.shipment_detail && row.shipment_detail[0]) ? `${((row.shipment_detail[0].item_weight) / 1000).toFixed(2)}kg` : ""}
                            </Grid>
                            <Grid item xs={12}>
                                Vol Weight : {(row.shipment_detail && row.shipment_detail[0]) ? `${((row.shipment_detail[0].item_length * row.shipment_detail[0].item_breadth * row.shipment_detail[0].item_height) / 5000).toFixed(2)}kg` : ""}
                            </Grid>
                            <Grid item xs={12}>
                                Dimensions : {(row.shipment_detail && row.shipment_detail[0]) ? `${row.shipment_detail[0].item_length} x ${row.shipment_detail[0].item_breadth} x ${row.shipment_detail[0].item_height} cm` : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'itemDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Item Details', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                <Button onClick={() => { setItemModal(true); setItemModalData(row.item_list) }}>Items</Button>
                            </Grid>
                            <Grid item xs={12}>
                                Total Units : {getTotalUnitsOfOrder(row.item_list)}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'paymentDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Invoice Amt', flex: 0.8, renderCell: (params) => {
                const { row } = params;
                // const paymentMode = row.payment_mode ? row.payment_mode : '';
                // const color = paymentMode === 'COD' ? "error" : "success";
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.invoice_value) ? "Rs. " + row.invoice_value : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'statusDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Status', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (<Chip variant="filled" label={row.orderStatus} />)
            },
        },
        {
            field: 'qcDetails', headerAlign: "center", hideSortIcons: true, headerName: 'QC Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                if (row.qcDetails) {
                    return (
                        <>
                            <Button onClick={() => handleOpen(row)}>View</Button>
                        </>
                    )
                }
                else {
                    return "-"
                }
            },
        },
        {
            field: 'action', headerAlign: "center", hideSortIcons: true, headerName: 'Action', flex: 1.4, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container rowSpacing={1}>
                        <Grid item xs={6}>
                            <Button variant="contained" size="small" color="warning" component={Link} to={`/order-detail?waybill=${row.awb_number}`}>Details</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" size="small" color="warning" onClick={() => handleClickClone(row.awb_number, row)}>Clone</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" size="small" color="warning" onClick={() => handleSync(row.awb_number, row.reference)}>Resync</Button>
                        </Grid>
                    </Grid>
                )
            },
        },
    ];
    const [row, setRow] = useState();
    const [viewQc, setViewQc] = useState(false)
    const handleOpen = (row) => {
        setViewQc(true)
        setRow(row)
    }
    const handleClose = () => {
        setViewQc(false)
    }
    const cloneOrder = (fill) => {
        // getShipmentDetail({
        //   "excludes": "sla,mongoLabelSignedUrlDetails,orderManifest,courierPricing,mongoInvoiceSignedUrlDetails,courierDetail,courier,courierId,merchant,merchantPricing,order_history", andfilter: [{ "field": "awbNumber", "operator": "eq", "value": id }], "offset": 0, "limit": 1
        // }).then((res) => {
        //   let data = res.data.data[0];
        setIsSubmitting(true)
        let newData = {}
        newData.order_type = resData.order_type;
        newData.order_date = new Date();
        newData.item_list = resData.item_list;
        newData.pickup_details = resData.pickup_details;
        newData.delivery_details = resData.delivery_details;
        newData.shipment_type = resData.shipment_type;
        newData.customer_detail = resData.customer_detail;
        newData.shipment_detail = resData.shipment_detail;
        newData.invoice_value = resData.invoice_value;
        newData.is_reverse = true;
        newData.rvp_reason = resData.rvp_reason;
        newData.payment_mode = fill.payment_mode;
        newData.cod_amount = fill.payment_mode === "PREPAID" ? 0 : resData.invoice_value;
        newData.client_order_id = resData.client_order_id;
        newData.invoice_number = resData.invoice_number;
        newData.shipping_charge = resData.shipping_charge;
        newData.transaction_charge = resData.transaction_charge;
        newData.giftwrap_charge = resData.giftwrap_charge;
        newData.shippingLabelId = resData.shippingLabelId;
        newData.reference = 'c' + resData.reference;
        newData.channelOrderUniqueKey = resData.channelOrderUniqueKey;
        newData.dispatch_mode = resData.dispatch_mode;
        newData.skipRule = fill.courier_type === "Other" ? true : false;
        newData.merchantOId = merchant?.id
        if (fill.courier_type !== "Other") {
            delete newData.courierPartner;
        } else {
            newData.courier_id = {
                prozoId: fill.courierPartner
            };
            delete newData.courierPartner;
        }
        try {
            postGeneric(apiConstants.CREATE_ORDER, newData).then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar(res?.data?.meta?.message || "Order cloned successfully", { variant: "success" });
                    setClone(() => false)
                    setIsSubmitting(false)
                }
            }).catch((err) => {
                console.log(err);
                // enqueueSnackbar(err?.response?.data?.message || "Something went wrong while cloning the order", { variant: "error" })
                enqueueSnackbar("Something went wrong while cloning the order", { variant: "error" })
                setIsSubmitting(false)
                setClone(() => false)
            }).finally(() => resetClone())
        } catch (err) {
            enqueueSnackbar("Something went wrong while cloning the order", { variant: "error" });
            setClone(() => false)
            setIsSubmitting(false)
        }
    };

    useEffect(() => {
        if (courierData && courierSelection === "Other") {
            setIsLoadingC(true)
            const pickup_details = {
                from_address: courierData?.pickup_details?.address_line,
                from_city: courierData?.pickup_details?.city,
                from_country: courierData?.pickup_details?.country,
                from_name: courierData?.pickup_details?.name,
                from_pincode: courierData?.pickup_details?.from_pincode,
                from_state: courierData?.pickup_details?.state,
                from_phone_number: courierData?.pickup_details?.contactNumber,
                from_email: courierData?.pickup_details?.email,
                gstin: courierData?.pickup_details?.gstin
            }

            let length = _.sumBy(courierData?.shipment_detail, "item_length")
            let breadth = _.sumBy(courierData?.shipment_detail, "item_breadth")
            let height = _.sumBy(courierData?.shipment_detail, "item_height")
            let weight = _.sumBy(courierData?.shipment_detail, "item_weight")
            if (pickup_details) {
                postGeneric(`${apiConstants.SHIPMENT_RATE_CALCULATOR}`, {
                    orderType: "RVP",
                    to: parseInt(courierData?.delivery_details?.to_pincode),
                    from: parseInt(pickup_details?.from_pincode),
                    paymentType: courierData?.payment_mode.toLowerCase() === "prepaid" ? "PREPAID" : "COD",
                    "length": length,
                    "breadth": breadth,
                    "height": height,
                    "weight": weight,
                    "dispatchMode": "SURFACE",
                    "codAmount": Number(courierData.cod_amount) || 0,
                    "isMerchant": true,
                    onlyCouriersAsPerRuleMapping: true,
                    merchantOid: merchant?.id

                }).then((res) => {
                    setIsLoadingC(false)
                    const pricing = res?.data;
                    const newCourier = allCouriers?.map((d) => {
                        const updatedCourier = { ...d };
                        pricing.forEach((t) => {
                            if (t.provider?.id === updatedCourier.prozoId) {
                                updatedCourier.priceAfterTax = t.priceAfterTax;
                                updatedCourier.providerMinTatDays = t.providerMinTatDays;
                                updatedCourier.providerMaxTatDays = t.providerMaxTatDays;
                            }
                        });
                        return updatedCourier;
                    })
                    let filteredCouriers = newCourier.filter(item =>
                        item.priceAfterTax &&
                        item.type === 'RVP'
                    )
                    const now = new Date();
                    filteredCouriers.forEach(shipment => {
                        let minTatDays = shipment.providerMinTatDays || 0;
                        let maxTatDays = shipment.providerMaxTatDays || 0;
                        shipment.minDeliveryDate = format(addDays(now, minTatDays), 'dd, MMM');
                        shipment.maxDeliveryDate = format(addDays(now, maxTatDays), 'dd, MMM');
                    });
                    setNewCourier(filteredCouriers);
                }).catch((err) => {
                    console.log(err)
                    enqueueSnackbar(err?.response?.data?.message || "Something went wrong", { variant: "error" });
                    setIsLoadingC(false)
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courierSelection, courierData])


    return (
        <>
            {isLoadingResync && <Loader />}
            <MUIModal open={viewQc} handleClose={handleClose} children={<QcDetails data={row} />} title={"Qc Link"} maxWidth='sm' />
            <div id="main-content" className="container-fluid">
                <PageTitle>RVP Shipments</PageTitle>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, boxShadow: 1, borderRadius: 0 }}>
                            <FormProvider methods={methods} onSubmit={handleSubmit(applyFilters)}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={2} md>
                                        <RHFTextField name="reference" label="Order/Reference Number" variant="filled" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" />
                                    </Grid>
                                    <Grid item xs={2} >
                                        <RHFTextField name="pickup_pincode" label="Pickup Pincode" variant="filled" />
                                    </Grid>
                                    {/* <Grid item xs={2} >
                                    <RHFTextField name="drop_pincode" label="Drop Pincode" variant="filled" />
                                </Grid> */}
                                    <Grid item xs={2}>
                                        <OrderStatusWiseFilter active={6} />
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Button type="submit" variant="contained">Filter</Button>
                                        <Button sx={{ ml: 1 }} type="submit" variant="contained" color="error" onClick={() => {
                                            reset()
                                        }}>Reset</Button>
                                    </Grid>
                                </Grid>
                            </FormProvider>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <ProzoDataGrid
                            columns={columns}
                            hideFooterPagination={false}
                            autoHeight={true}
                            rows={data?.data || []}
                            sizePerPage={sizePerPage}
                            setSizePerPage={setSizePerPage}
                            setPage={setPage}
                            page={page}
                            pagination={true}
                            filterMode={"server"}
                            rowCount={totalRows}
                            getRowId={(row) => row.id} />
                    </Grid>
                </Grid>
                {(isLoading || isFetching) && <Loader />}
                <ItemListModal data={itemModalData} itemModal={itemModal} setItemModal={setItemModal} />
                <MUIModal
                    maxWidth="sm"
                    open={clone}
                    handleClose={() => { setClone(false); resetClone() }}
                    title={"Are you sure you want to clone this order?"}
                    hideClose={true}
                // sx={{ width: '100%', justifyContent: 'center' }}
                >
                    <FormProvider methods={methods2} onSubmit={submitClone(cloneOrder)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <RHFSelectField name='payment_mode' width={"100%"} label='Payment Mode' options={paymentMode.map((val) => ({
                                    value: val.value,
                                    label: val.label
                                }))} />
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1, ml: 1 }}>
                                <RHFRadioGroup label="Courier Selection" name="courier_type" options={[
                                    {
                                        label: "As per Recommendation Rules",
                                        value: "Prozo Choice",
                                    },
                                    { label: "Other Courier", value: "Other" },
                                ]}

                                />
                            </Grid>
                            {isLoadingC ? <Loader /> :
                                <>
                                    {courierSelection === 'Other' && (
                                        <Grid item xs={12}>
                                            <RHFSelectField
                                                variant="filled"
                                                width="100%"
                                                name="courierPartner"
                                                label="Courier Partner"
                                                noOptionsText='No courier partner available for this pickup location'
                                                options={(newCourier)?.filter(row => !row.isExternal).map((c) => {
                                                    return {
                                                        label: <Grid container spacing={0}>
                                                            <Grid item xs={3}>
                                                                <Typography variant="body2">{c?.name}</Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="body2">{formatCurrency(c?.priceAfterTax)}</Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="body2">
                                                                    {c.providerMinTatDays !== undefined && c.providerMaxTatDays !== undefined && c.providerMaxTatDays > 0 ?
                                                                        `TAT: ${c.providerMinTatDays}-${c.providerMaxTatDays} Days` :
                                                                        'TAT: Not Available'}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="body2">
                                                                    {c.providerMinTatDays !== undefined && c.providerMaxTatDays !== undefined && c.providerMaxTatDays > 0 ?
                                                                        `EDD: ${c.minDeliveryDate}-${c.maxDeliveryDate}` :
                                                                        'EDD: Not Available'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>,
                                                        value: c?.prozoId
                                                    };
                                                })}
                                            />
                                        </Grid>
                                    )}
                                </>
                            }
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', p: 2 }}>
                                <Button variant="contained" color="error" onClick={() => setClone(false)}>No</Button>
                                <LoadingButton loading={isSubmitting} variant="contained" color="success" type='submit' sx={{ ml: 1 }}>YES</LoadingButton>
                            </Grid>
                        </Grid>
                    </FormProvider>
                </MUIModal>
            </div>
        </>
    )
}
export default withLayout(ReverseOrders)