import React, { useEffect, useState } from 'react';
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { Chip, Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderLists, syncOrder } from '../../../redux/actions/orderList/orderListAction';
import { resetOrderList } from '../../../redux/reducers/orderList/orderListSlice';
import Loader from '../../../common/Loader';
import { parseDateAndTime, getTotalUnitsOfOrder, maskAddress, shouldMaskData, formatDateForApi } from '../../../helpers/UtilityHelper';
import 'react-toastify/dist/ReactToastify.css';
import { exclude, ItemListModal } from './index';
import { Link } from 'react-router-dom'
import MUIModal from '../../../common/MUIModal';
import Pods from './Pods';
import PlacedReversedOrder from './PlaceReversedOrder';
import PageTitle from '../../../common/PageTitle';

const DeliveredOrders = (props) => {
  const dispatch = useDispatch();
  const { orderListInfo, loading } = useSelector((state) => state.orderListSlice);
  const { loading: orderSummaryLoading } = useSelector((state) => state.orderSummarySlice);
  const [page, setPage] = useState(0);
  const [reload, setReload] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [rowCountState, setRowCountState] = useState(props?.count || 0);
  const [itemModal, setItemModal] = useState(false);
  const [itemModalData, setItemModalData] = useState();
  const [open, setOpen] = useState(false);
  const [pod, setPod] = useState()
  const [revered, setReversed] = useState(false)
  const { report } = useSelector(state => state);


  useEffect(() => {
    const andfilter = [];
    const orFilter = [];
    if (props.merchantId) {
      andfilter.push({
        field: "merchant.$id",
        operator: "eq",
        value: props.merchantId,
      });
    }
    // from date
    if (props.filters.status) {
      andfilter.push({ "field": "orderStatus", "operator": "in", "value": [props.filters.status] })
    } else {
      andfilter.push({ "field": "orderStatus", "operator": "in", "value": ['DELIVERED'] });
    }
    if (props.shipmentType === 'B2C') {
      andfilter.push({ field: "orderType", operator: "eq", value: 'FORWARD SHIPMENT' });
    }
    andfilter.push({ field: "shipmentType", operator: "eq", value: props.shipmentType });


    // if (!(props.filters.fromDate && props.filters.toDate)) {
    //   andfilter.push({ field: "awbRegisteredDate", operator: "gte", value:props.startDateForLastThirtyDays})
    // }


    if (props.filters) {
      // if (props.filters.reference) {
      //   andfilter.push({ "field": "reference", "operator": "eq", "value": props.filters.reference });
      // }
      if (props.filters.awb) {
        andfilter.push({ "field": "awbNumber", "operator": "eq", "value": props.filters.awb });
      }
      if (props.filters.city) {
        andfilter.push({ "field": "deliveryDetails.to_city", "operator": "eq", "value": props.filters.city });
      }
      if (props.filters.state) {
        andfilter.push({ "field": "deliveryDetails.to_state", "operator": "eq", "value": props.filters.state });
      }
      if (props.filters.fromDate) {
        andfilter.push({ field: "awbRegisteredDate", operator: "gte", value: formatDateForApi(props.filters.fromDate) })
      }
      if (props.filters.toDate) {
        andfilter.push({ field: "awbRegisteredDate", operator: "lte", value: formatDateForApi(props.filters.toDate) })
      }
      if (props.filters.payment_mode) {
        andfilter.push({
          field: "paymentMode",
          operator: "eq",
          value: props.filters.payment_mode,
        });
      }
    }
    if (props.filters) {
      if (props.filters.reference) {
        orFilter.push({ "field": "reference", "operator": "eq", "value": props.filters.reference });
      }
      if (props.filters.reference) {
        orFilter.push({ "field": "shippingLabelId", "operator": "eq", "value": props.filters.reference });
      }

    }
    dispatch(resetOrderList());
    dispatch(getOrderLists({ "excludes": exclude, "andfilter": andfilter, "orfilter": orFilter, "offset": (page * sizePerPage), "limit": sizePerPage, "skipCount": true, "sort": { "field": "awbRegisteredDate", "direction": "DESC" } }));
    setRowCountState(props.count ? props.count : Number.MAX_VALUE);

    // eslint-disable-next-line
  }, [page, sizePerPage, props.filterChange, reload]);

  const handleSync = (awbNumber, reference) => {
    if (awbNumber) {
      dispatch(syncOrder({ waybill: awbNumber, reference: reference }));
      // console.log(orderListInfo);
    }
  }
  const handleClickOpen = (row) => {
    setOpen(true);
    setPod(row);
  };

  const handleClose = () => {
    setOpen(false);
    setReversed(false)
  };
  const handleClickReverseOrder = (row) => {
    setReversed(true);
    setPod(row);
  }


  const columns = [
    {
      field: 'actions', headerAlign: "center", hideSortIcons: true, headerName: 'Order Details', flex: 1.3, renderCell: (params) => {
        const { row } = params;
        return (
          <>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} sx={{ display: 'flex' }}>
                {row.reference}
              </Grid>
              {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                <Grid item xs={12} sx={{ display: 'flex' }}>
                  {row.shippingLabelId}
                </Grid>
              )}
              <Grid item xs={12} sx={{ display: 'flex' }}>
                {(row.createdDate) ? parseDateAndTime(row.createdDate) : ""}
              </Grid>
              {row.channel_name && row.channel_name !== 'Unicom' && row.channel_name !== 'WMS' &&
                <Grid item xs={12}>
                  {(row.channel_name) ? row.channel_name : ""}
                </Grid>
              }
            </Grid>
          </>
        )
      },
    },
    {
      field: "courier",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Courier Partner",
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        let courierParent = row.courierDetail?.parent?.includes('Blended') ? row.actualCourierProviderName : row.courierDetail?.parent;
        return (
          <>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                AWB: <b>{(row.awb_number) ? row.awb_number : ""}</b>
              </Grid>
              <Grid item xs={12}>
                Courier : {courierParent}<br />
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      field: 'delivery', headerAlign: "center", hideSortIcons: true, headerName: 'Delivery Details', flex: 1.4, renderCell: (params) => {
        const { row } = params;
        return (
          <>
            <div className="col-12 col-md position-relative mt-4 mt-md-0">
              <div className="row g-0">
                <div className="col-6 col-md-12 d-flex delivery-location flex-column flex-md-row">
                  <div className="flex-shrink-0"> <img src="img/location.svg" height="17" alt="" /> </div>
                  <div className="flex-grow-1 ms-md-3 mt-2 mt-md-0">{(row.pickup_details && row.pickup_details.from_name) ? row.pickup_details.from_name : "-NA-"}.<br />
                    {(row.pickup_details && row.pickup_details.from_pincode) ? row.pickup_details.from_pincode : "-NA-"}</div>
                </div>
                <div className="col-6 col-md-12 d-flex delivery-location flex-column flex-md-row">
                  <div className="flex-shrink-0 text-end text-md-start"> <img src="img/location.svg" height="17" alt="" /> </div>
                  <div className="flex-grow-1 ms-md-3 d-flex justify-content-end justify-content-md-start mt-2 mt-md-0">{(row.delivery_details && row.delivery_details.to_name) ? row.delivery_details.to_name : ""}<br />
                    {(row.delivery_details && row.delivery_details.to_pincode) ? (shouldMaskData(report, row) ? maskAddress(row.delivery_details.to_pincode) : row.delivery_details.to_pincode) : "-NA-"}
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      },
    },
    {
      field: 'shipment', headerAlign: "center", hideSortIcons: true, headerName: 'Package Details', flex: 1.4, renderCell: (params) => {
        const { row } = params;
        return (
          <>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                Dead Weight : {(row.shipment_detail && row.shipment_detail[0]) ? `${((row.shipment_detail[0].item_weight) / 1000).toFixed(2)}kg` : ""}
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                Vol Weight : {(row.shipment_detail && row.shipment_detail[0]) ? `${((row.shipment_detail[0].item_length * row.shipment_detail[0].item_breadth * row.shipment_detail[0].item_height) / 5000).toFixed(2)}kg` : ""}
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                Dimensions : {(row.shipment_detail && row.shipment_detail[0]) ? `${row.shipment_detail[0].item_length} x ${row.shipment_detail[0].item_breadth} x ${row.shipment_detail[0].item_height} cm` : ""}
              </Grid>
            </Grid>
          </>
        )
      },
    },
    {
      field: 'pods', headerAlign: "center", hideSortIcons: true, headerName: 'PODs', flex: 0.8, renderCell: (params) => {
        const { row } = params;
        if (row.pods) {
          return (
            <>

              <Grid container rowSpacing={2}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button onClick={() => handleClickOpen(row)}>PODs</Button>
                </Grid>
              </Grid>

            </>

          )
        }
      },
    },
    {
      field: 'itemDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Item Details', flex: 0.9, renderCell: (params) => {
        const { row } = params;
        return (
          <>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={() => { setItemModal(true); setItemModalData(row.item_list) }}>Items</Button>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                Total Units : {getTotalUnitsOfOrder(row.item_list)}
              </Grid>
            </Grid>
          </>
        )
      },
    },
    {
      field: 'paymentDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Payment Details', flex: 0.9, renderCell: (params) => {
        const { row } = params;
        const paymentMode = row.payment_mode ? row.payment_mode : '';
        const color = paymentMode === 'COD' ? "error" : "success";
        return (
          <>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                {(row.invoice_value) ? "Rs. " + row.invoice_value : ""}
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Chip size="small" color={color} label={paymentMode} />
              </Grid>
            </Grid>
          </>
        )
      },
    },
    {
      field: 'statusDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Status', flex: 1.1, renderCell: (params) => {
        const { row } = params;
        return (<Chip variant="filled" label={row.orderStatus} sx={{ display: 'flex', justifyContent: 'center' }} />)
      },
    },
    {
      field: 'action', headerAlign: "center", hideSortIcons: true, headerName: 'Action', flex: 1.2, renderCell: (params) => {
        const { row } = params;
        return (
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <Button variant="contained" size="small" color="warning" component={Link} to={`/order-detail?waybill=${row.awb_number}`}>Details</Button>
            </Grid>
            <Grid item xs={12} >
              <Button variant="contained" size="small" color="warning" onClick={() => handleSync(row.awb_number, row.reference)}>Resync</Button>
            </Grid>
            {/* {!row?.returnItemDetails?.allItemReturned && */}
            <Grid item xs={12}>
              <Button variant="contained" size="small" color="warning" disabled={row?.returnItemDetails?.allItemReturned} onClick={() => handleClickReverseOrder(row)}>Create RVP </Button>
            </Grid>
            {/* } */}
            {/* {!row.reverseOrderId &&
              <Grid item xs={12}>
                <Button variant="contained" size="small" color="warning" component={Link} to={`/order-detail?waybill=${row.awb_number}`}>Details</Button>
              </Grid>
            } */}
          </Grid>
        )
      },
    },
  ];
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      props?.count !== undefined ? props?.count : prevRowCountState,
    );
  }, [props?.count, setRowCountState]);
  // console.log(orderListInfo.data)

  return (
    <>
      <PageTitle>Delivered Shipments</PageTitle>
      <MUIModal open={revered} handleClose={handleClose} children={<PlacedReversedOrder pod={pod} setReversed={setReversed} setReload={setReload} />}
        title={"Place RVP Request"}
      />
      <Grid item container spacing={2}>
        {(loading || orderSummaryLoading) && <Loader />}
        <Grid item xs={12}>
          <ProzoDataGrid columns={columns}
            hideFooterPagination={false}
            // disableColumnFilter={true}rowsPerPageOptions
            autoHeight={true}
            rows={(orderListInfo.data && orderListInfo.data.length > 0) ? orderListInfo.data : []}
            sizePerPage={sizePerPage}
            setSizePerPage={setSizePerPage}
            setPage={setPage}
            page={page}
            pagination={true}
            filterMode={"server"}
            rowHeight={180}
            rowCount={rowCountState}
            getRowId={(row) => row.id} />
        </Grid>
      </Grid>
      <MUIModal open={open} handleClose={handleClose} children={<Pods id={pod} />} title={"PODs Link"} />
      <ItemListModal data={itemModalData} itemModal={itemModal} setItemModal={setItemModal} />
    </>
  );
};

export default DeliveredOrders


