import React, { useEffect, useState } from 'react';
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { Chip, Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderLists, syncOrder } from '../../../redux/actions/orderList/orderListAction';
import { resetOrderList } from '../../../redux/reducers/orderList/orderListSlice';
import Loader from '../../../common/Loader';
import { parseDateAndTime, getTotalUnitsOfOrder, formatDateForApi } from '../../../helpers/UtilityHelper';
import { exclude, ItemListModal } from './index';
import { Link } from 'react-router-dom'
import PageTitle from '../../../common/PageTitle';


const DeliveryFailedOrders = (props) => {
  const dispatch = useDispatch();
  const { orderListInfo, loading } = useSelector((state) => state.orderListSlice);
  const { loading: orderSummaryLoading } = useSelector((state) => state.orderSummarySlice);
  const [page, setPage] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [rowCountState, setRowCountState] = useState(props?.count || 0);
  const [itemModal, setItemModal] = useState(false);
  const [itemModalData, setItemModalData] = useState();

  useEffect(() => {
    const andfilter = [];
    const orFilter = [];

    if (props.merchantId) {
      andfilter.push({
        field: "merchant.$id",
        operator: "eq",
        value: props.merchantId,
      });
    }
    if (props.filters.status) {
      andfilter.push({ "field": "orderStatus", "operator": "in", "value": [props.filters.status] })
    } else {
      andfilter.push({ "field": "orderStatus", "operator": "in", "value": ['FAILED_DELIVERY'] });
    }
    andfilter.push({ field: "shipmentType", operator: "eq", value: props.shipmentType });

    if (props.shipmentType === 'B2C') {
      andfilter.push({ field: "orderType", operator: "eq", value: 'FORWARD SHIPMENT' });
    }

    // if (!(props.filters.fromDate && props.filters.toDate)) {
    //   andfilter.push({ field: "awbRegisteredDate", operator: "gte", value: props.startDateForLastThirtyDays })
    // }

    if (props.filters) {
      // if (props.filters.reference) {
      //   andfilter.push({ "field": "reference", "operator": "eq", "value": props.filters.reference });
      // }
      if (props.filters.awb) {
        andfilter.push({ "field": "awbNumber", "operator": "eq", "value": props.filters.awb });
      }
      if (props.filters.payment_mode) {
        andfilter.push({
          field: "paymentMode",
          operator: "eq",
          value: props.filters.payment_mode,
        });
      }
      if (props.filters.city) {
        andfilter.push({ "field": "deliveryDetails.to_city", "operator": "eq", "value": props.filters.city });
      }
      if (props.filters.state) {
        andfilter.push({ "field": "deliveryDetails.to_state", "operator": "eq", "value": props.filters.state });
      }
      if (props.filters) {
        if (props.filters.reference) {
          orFilter.push({ "field": "reference", "operator": "eq", "value": props.filters.reference });
        }
        if (props.filters.reference) {
          orFilter.push({ "field": "shippingLabelId", "operator": "eq", "value": props.filters.reference });
        }

      }
      if (props.filters.fromDate) {
        andfilter.push({ field: "awbRegisteredDate", operator: "gte", value: formatDateForApi(props.filters.fromDate) })
      }
      if (props.filters.toDate) {
        andfilter.push({ field: "awbRegisteredDate", operator: "lte", value: formatDateForApi(props.filters.toDate) })
      }
    }

    dispatch(resetOrderList());
    dispatch(getOrderLists({ "excludes": exclude, "andfilter": andfilter, "orfilter": orFilter, "offset": (page * sizePerPage), "limit": sizePerPage, "skipCount": true, "sort": { "field": "awbRegisteredDate", "direction": "DESC" } }));
    setRowCountState(props.count ? props.count : Number.MAX_VALUE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sizePerPage, props.filterChange]);

  const handleSync = (awbNumber, reference) => {
    if (awbNumber) {
      dispatch(syncOrder({ waybill: awbNumber, reference: reference }));
    }
  }

  const columns = [
    {
      field: 'det', headerAlign: "center", hideSortIcons: true, headerName: 'Order Details', flex: 1.3, renderCell: (params) => {
        const { row } = params;
        return (
          <>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                {row.reference}
              </Grid>
              {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                <Grid item xs={12}>
                  {row.shippingLabelId}
                </Grid>
              )}
              <Grid item xs={12}>
                {(row.createdDate) ? parseDateAndTime(row.createdDate) : ""}
              </Grid>
              {row.channel_name && row.channel_name !== 'Unicom' && row.channel_name !== 'WMS' &&
                <Grid item xs={12}>
                  {(row.channel_name) ? row.channel_name : ""}
                </Grid>
              }
            </Grid>
          </>
        )
      },
    },
    {
      field: "courier",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Courier Partner",
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        let courierParent = row.courierDetail?.parent?.includes('Blended') ? row.actualCourierProviderName : row.courierDetail?.parent;
        return (
          <>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                AWB: <b>{(row.awb_number) ? row.awb_number : ""}</b>
              </Grid>
              <Grid item xs={12}>
                Courier : {courierParent}<br />
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      field: 'pickup', headerAlign: "center", hideSortIcons: true, headerName: 'Pickup Details', flex: 1.5, renderCell: (params) => {
        const { row } = params;
        return (
          <Grid container >
            <Grid item xs={12}>
              {row.pickup_details.from_name}
            </Grid>
            <Grid item xs={12}>
              {row.pickup_details.from_address},{row.pickup_details.from_city},{row.pickup_details.from_pincode}
            </Grid>

          </Grid>
        )
      },
    },
    {
      field: 'delivery', headerAlign: "center", hideSortIcons: true, headerName: 'Delivery Details', flex: 1.6, renderCell: (params) => {
        const { row } = params;
        return (
          <Grid container >
            <Grid item xs={12}>
              <b>Name : </b>{row.delivery_details.to_name}
            </Grid>
            <Grid item xs={12}>
              <b>Phone : </b>{row.delivery_details.to_phone_number}
            </Grid>
            <Grid item xs={12}>
              {row.delivery_details.to_address},{row.delivery_details.to_city},{row.delivery_details.to_state},{row.delivery_details.to_pincode}
            </Grid>

          </Grid>
        )
      },
    },
    {
      field: 'itemDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Item Details', flex: 1, renderCell: (params) => {
        const { row } = params;
        return (
          <>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    setItemModalData(row.item_list);
                    setItemModal(true);
                  }}
                >
                  Items</Button>
              </Grid>
              <Grid item xs={12}>
                Total Units : {getTotalUnitsOfOrder(row.item_list)}
              </Grid>
            </Grid>
          </>
        )
      },
    },
    {
      field: 'paymentDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Payment Details', flex: 0.8, renderCell: (params) => {
        const { row } = params;
        const paymentMode = row.payment_mode ? row.payment_mode : '';
        const color = paymentMode === 'COD' ? "error" : "success";
        return (
          <>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                {(row.invoice_value) ? "Rs. " + row.invoice_value : ""}
              </Grid>
              <Grid item xs={12}>
                <Chip size="small" color={color} label={paymentMode} />
              </Grid>
            </Grid>
          </>
        )
      },
    },
    {
      field: 'statusDetails', headerAlign: "center", hideSortIcons: true, headerName: 'Status', flex: 1.3, renderCell: (params) => {
        const { row } = params;
        return (<Chip variant="filled" label={row.orderStatus} />)
      },
    },
    {
      field: 'action', headerAlign: "center", hideSortIcons: true, headerName: 'Action', flex: 0.8, renderCell: (params) => {
        const { row } = params;
        return (
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <Button variant="contained" size="small" color="warning" component={Link} to={`/order-detail?waybill=${row.awb_number}`}>Details</Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" size="small" color="warning" onClick={() => handleSync(row.awb_number, row.reference)}>Resync</Button>
            </Grid>

          </Grid>
        )
      },
    },
  ];

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      props?.count !== undefined ? props?.count : prevRowCountState,
    );
  }, [props?.count, setRowCountState]);

  return (
    <>
      <PageTitle>Delivery Failed Shipments</PageTitle>
      <Grid item container spacing={2}>
        {(loading || orderSummaryLoading) && <Loader />}
        <Grid item xs={12}>
          <ProzoDataGrid columns={columns}
            hideFooterPagination={false}
            // disableColumnFilter={true}rowsPerPageOptions
            autoHeight={true}
            rows={(orderListInfo.data && orderListInfo.data.length > 0) ? orderListInfo.data : []}
            sizePerPage={sizePerPage}
            setSizePerPage={setSizePerPage}
            setPage={setPage}
            page={page}
            pagination={true}
            filterMode={"server"}
            rowHeight={180}
            rowCount={rowCountState}
            getRowId={(row) => row.id} />
        </Grid>
      </Grid>

      <ItemListModal data={itemModalData} itemModal={itemModal} setItemModal={setItemModal} />
    </>
  );
};

export default DeliveryFailedOrders
