import { Chip, Grid, Button, Paper, Typography } from '@mui/material';
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { FormProvider, RHFSelectField, RHFTextField } from "../../../common/hook-form"
import Loader from "../../../common/Loader"
import { parseDateAndTime } from "../../../helpers/UtilityHelper"
import { useLazyGetPaginatedShipmentsQuery } from "../../../redux/commonRTK"
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import _ from "lodash";
import { apiConstants, postGeneric } from '../../../common/constants';
import { useSnackbar } from 'notistack';
import PageTitle from '../../../common/PageTitle';
import { getMerchant } from '../../../helpers/ReduxHelpers';
import { ManifestCourierFilter } from '../../../common/hook-form/CourierFilter';
import { LoadingButton } from '@mui/lab';

const excludes = "additionalData,orderDate,shipmentType,dispatchMode,invoiceNumber,channelName,orderType,pickupDetails.pickupTime,pickupDetails.from_phone_number,pickupDetails.from_email,pickupDetails.from_country,pickupDetails.from_address,pickupDetails.gstin,pickupDetails.from_state,courierDetail.name,courierDetail.prozoId,gstInfo,shipmentDetail,deliveryDetails,returnInfo,deliveryTime,itemList,actualDeliveryTat,proshipDeliveryTat,courierDeliveryTat,actualPickUpTime,lastStatusUpdateTime,edd,epd,lifeCycleComplete,courier,merchantDetail,courierId,version,order_history,orderManifest,invoiceUrl,courierPricingRTO,courierPricing,overriddenCourierAccountCode,isDiscrepancyRaised,discrepancyId,securityKey,sortCode,channelType,createdByUser,skipRule,modifiedByUser,rvp_reason,channel_name,customer_detail,cpErrorList,mongoLabelSignedUrlDetails,overriddenCourierRule,mongoInvoiceSignedUrlDetails,merchant,merchantPricing,courierPricingRTO,modifiedByUser,lastModifiedDate,terminalStatus,merchantPricingRTO,customerDetail";


function PickupsPending() {
    const merchant = getMerchant();
    const merchantId = merchant.id ? merchant.id : ''

    const [reload, setReload] = useState(0)
    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = useState(0);
    const [isLoadingP, setIsLoadingP] = useState(false);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [andFilters, setAndFilters] = useState([]);
    const [isBulkManifestEnabled, setIsBulkManifestEnabled] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [selectedAwbNumbers, setSelectedAwbNumbers] = useState([]);
    const [combinedData, setCombinedData] = useState([]);
    const [getPaginatedShipments, { data, isLoading, isFetching }] = useLazyGetPaginatedShipmentsQuery()

    const defaultFilters = [
        // { field: "is_reverse", operator: "eq", value: true },
        { field: "orderStatus", operator: "in", value: ['ORDER_PLACED', 'PICKUP_PENDING', 'PICKUP_FAILED', 'OUT_FOR_PICKUP', 'AWB_REGISTERED'] },
        { field: "manifestDetails", operator: "exists", value: false },
        { field: "shipmentType", operator: "eq", value: "B2C" },
        { field: "merchant.$id", operator: "eq", value: merchantId }]

    const beardoChannels = [{
        "id": "SHOPIFY",
        "parent": "SHOPIFY"
    },
    {
        "id": "WMS",
        "parent": "WMS"
    },
    {
        "id": "Proship",
        "parent": "Proship"
    },
    ]

    useEffect(() => {
        getPaginatedShipments({
            offset: (page * sizePerPage),
            limit: sizePerPage,
            andfilter: [...defaultFilters, ...andFilters],
            excludes
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, sizePerPage, andFilters, reload])

    useEffect(() => {
        if (data?.data) {
            setCombinedData(prevData => {
                const newData = data.data.filter(newItem =>
                    !prevData.some(prevItem => prevItem.id === newItem.id)
                );
                return [...prevData, ...newData];
            });
        }
    }, [data?.data]);

    useEffect(() => {
        const resultArray = combinedData.filter(obj =>
            rowSelectionModel.includes(obj.id)
        );
        if (resultArray?.length > 0) {
            setSelectedAwbNumbers(resultArray)
        }

        // const courierIds = resultArray.map(obj => obj.courierDetail.key);
        // if (courierIds.length > 0) {
        //     const allCourierIdsMatch = _.every(courierIds, id => id === courierIds[0]);

        //     if (allCourierIdsMatch) {
        //         setIsBulkManifestEnabled(true);
        //         setSelectedAwbNumbers(resultArray);
        //     } else {
        //         setIsBulkManifestEnabled(false);
        //     }
        // } else {
        //     setIsBulkManifestEnabled(false);
        // }

    }, [JSON.stringify(rowSelectionModel), combinedData]);

    const methods = useForm({
        defaultValues: {
            courier: null,
            pickup_pincode: '',
            awb: '',
            channel: ''
        }
    });

    const {
        handleSubmit,
        reset
    } = methods;

    const applyFilters = (filters) => {
        let appliedFilters = [];
        if (filters.courier) {
            // appliedFilters.push({ "field": "courier.$id", "operator": "eq", "value": 'ObjectId(\'' + filters.courier + '\')' });
            appliedFilters.push({ "field": "courierDetail.key", "operator": "eq", "value": filters.courier });
        }
        if (filters.pickup_pincode) {
            appliedFilters.push({ "field": "pickupDetails.from_pincode", "operator": "eq", "value": filters.pickup_pincode })
        }

        if (filters.awb) {
            appliedFilters.push({ "field": "awbNumber", "operator": "eq", "value": filters.awb })
        }
        if (filters.channel) {
            appliedFilters.push({ "field": "channelName", "operator": "eq", "value": filters.channel })
        }
        setAndFilters(appliedFilters)
    }

    const columns = [
        {
            field: 'actions', headerAlign: "left", hideSortIcons: true, headerName: 'Order Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row.reference}
                            </Grid>
                            {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                                <Grid item xs={12}>
                                    {row.shippingLabelId}
                                </Grid>
                            )}

                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'channel', headerAlign: "left", hideSortIcons: true, headerName: 'Order Date', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.createdDate) ? parseDateAndTime(row.createdDate) : ""}
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'awb', headerAlign: "left", hideSortIcons: true, headerName: 'AWB Number', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {row?.awb_number}<br />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'courier', headerAlign: "left", hideSortIcons: true, headerName: 'Courier Partner', flex: 1, renderCell: (params) => {
                const { row } = params;
                let courierParent = (row.courierDetail?.parent)?.includes('Blended') ? row.actualCourierProviderName : row.courierDetail?.parent;
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {courierParent}<br />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'pickup', headerAlign: "left", hideSortIcons: true, headerName: 'Pickup Details', flex: 1.2, renderCell: (params) => {
                const { row } = params;
                return (
                    <Grid container >
                        <Grid item xs={12}>
                            Name : {row.pickup_details.from_name}
                        </Grid>
                        <Grid item xs={12}>
                            {row.pickup_details.from_address},{row.pickup_details.from_city},{row.pickup_details.from_pincode}
                        </Grid>

                    </Grid>
                )
            },
        },
        // {
        //     field: 'shipment', headerAlign: "left", hideSortIcons: true, headerName: 'Package Details', flex: 1.2, renderCell: (params) => {
        //         const { row } = params;
        //         return (
        //             <>
        //                 <Grid container rowSpacing={2}>
        //                     <Grid item xs={12}>
        //                         Dead Weight : {(row.shipment_detail && row.shipment_detail[0]) ? `${((row.shipment_detail[0].item_weight) / 1000).toFixed(2)}kg` : ""}
        //                     </Grid>
        //                     <Grid item xs={12}>
        //                         Vol Weight : {(row.shipment_detail && row.shipment_detail[0]) ? `${((row.shipment_detail[0].item_length * row.shipment_detail[0].item_breadth * row.shipment_detail[0].item_height) / 5000).toFixed(2)}kg` : ""}
        //                     </Grid>
        //                     <Grid item xs={12}>
        //                         Dimensions : {(row.shipment_detail && row.shipment_detail[0]) ? `${row.shipment_detail[0].item_length} x ${row.shipment_detail[0].item_breadth} x ${row.shipment_detail[0].item_height} cm` : ""}
        //                     </Grid>
        //                 </Grid>
        //             </>
        //         )
        //     },
        // },
        // {
        //     field: 'itemDetails', headerAlign: "left", hideSortIcons: true, headerName: 'Item Details', flex: 1, renderCell: (params) => {
        //         const { row } = params;
        //         return (
        //             <>
        //                 <Grid container rowSpacing={2}>
        //                     <Grid item xs={12}>
        //                         <Button onClick={() => { setItemModal(true); setItemModalData(row.item_list) }}>Items</Button>
        //                     </Grid>
        //                     <Grid item xs={12}>
        //                         Total Units : {getTotalUnitsOfOrder(row.item_list)}
        //                     </Grid>
        //                 </Grid>
        //             </>
        //         )
        //     },
        // },
        {
            field: 'paymentDetails', headerAlign: "left", hideSortIcons: true, headerName: 'Payment Details', flex: 1, renderCell: (params) => {
                const { row } = params;
                const paymentMode = row.payment_mode ? row.payment_mode : '';
                const color = paymentMode === 'COD' ? "error" : "success";
                return (
                    <>
                        <Grid container rowSpacing={2}>
                            <Grid item xs={12}>
                                {(row.invoice_value) ? "Rs. " + row.invoice_value : ""}
                            </Grid>
                            <Grid item xs={12}>
                                <Chip size="small" color={color} label={paymentMode} />
                            </Grid>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'statusDetails', headerAlign: "left", hideSortIcons: true, headerName: 'Status', flex: 1, renderCell: (params) => {
                const { row } = params;
                return (<Chip variant="filled" label={row.orderStatus} />)
            },
        },

    ];

    const bulkManifestFunc = () => {
        if (selectedAwbNumbers?.length > 0) {
            setIsLoadingP(true)
            postGeneric(apiConstants.GENERATE_MANIFEST, {
                awb_numbers: selectedAwbNumbers.map(obj => obj.awb_number)
            }).then((res) => {
                if (res.status === 200) {
                    enqueueSnackbar("Manifest Generated Successfully", { variant: "success" });
                    setSelectedAwbNumbers([])
                    setRowSelectionModel([])
                    setReload(reload + 1)
                }
            }).catch((err) => {
                console.log(err)
                let message = err?.response?.data?.message ? err?.response?.data?.message : "Something went wrong while generating manifest "
                enqueueSnackbar(message, { variant: "error" })
            }).finally(() => setIsLoadingP(false))
        } else {
            enqueueSnackbar("Select at least one row to proceed.", { variant: "warning" });
        }
    }

    return (
        <>
            {(isLoading || isFetching) && <Loader />}
            <PageTitle>Pending Manifest</PageTitle>
            <Grid container spacing={2}>
                <Grid item xs={12} spacing={2}>
                    <Paper sx={{ p: 2, boxShadow: 1, borderRadius: 0 }}>
                        <FormProvider methods={methods} onSubmit={handleSubmit(applyFilters)}>
                            <Grid container spacing={2} alignItems="center">
                                {merchantId === '65f83d1b9e30dd05590fca9f' && (
                                    <Grid item xs={2}>
                                        <RHFSelectField width="100%" name="channel" label={`Select Channel`} options={beardoChannels.map((val) => ({
                                            value: val.id,
                                            label: val.parent
                                        }))} />
                                        {/* <RHFSelectField width="100%" name="channel" label={`Select Channel`} options={_.orderBy(allChannelName, 'name').map((val) => ({
                                            value: val.id,
                                            label: val.parent
                                        }))} /> */}
                                    </Grid>
                                )}
                                <Grid item xs={3} sx={{ overflow: 'hidden' }}>
                                    <ManifestCourierFilter />
                                </Grid>
                                <Grid item xs={2} >
                                    <RHFTextField name="pickup_pincode" label="Pickup Pincode" variant="filled" />
                                </Grid>
                                <Grid item xs={2}>
                                    <RHFTextField name="awb" label="AWB Number" color="secondary" variant="filled" />
                                </Grid>
                                <Grid item xs={4} >
                                    <Button type="submit" variant="contained">Filter</Button>
                                    <Button sx={{ ml: 1 }} type="submit" variant="contained" color="error" onClick={() => {
                                        reset()
                                    }}>Reset</Button>
                                    {/* {isBulkManifestEnabled && ( */}
                                    <LoadingButton sx={{ ml: 1 }} variant="contained" loading={isLoadingP} onClick={bulkManifestFunc}>Generate Manifest</LoadingButton>
                                    {/* )} */}
                                </Grid>
                                {!isBulkManifestEnabled && (
                                    <Grid item xs={12}>
                                        <Typography sx={{ ml: 2 }} variant='body' color={'error'}>All shipments should belong to the same courier partner</Typography>
                                    </Grid>
                                )}
                                {isBulkManifestEnabled && (
                                    <Grid item xs={12}>
                                        <Typography sx={{ ml: 2 }} variant='body' color={'primary'}>All shipments belong to same courier partner</Typography>
                                    </Grid>
                                )}
                            </Grid>

                        </FormProvider>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <ProzoDataGrid
                        columns={columns}
                        hideFooterPagination={false}
                        autoHeight={true}
                        rows={data?.data || []}
                        checkboxSelection={true}
                        keepNonExistentRowsSelected={true}
                        sizePerPage={sizePerPage}
                        setSizePerPage={setSizePerPage}
                        setPage={setPage}
                        page={page}
                        rowSelectionModel={rowSelectionModel}
                        setRowSelectionModel={setRowSelectionModel}
                        pagination={true}
                        rowCount={data?.count || 0}
                        filterMode={"server"}
                        getRowId={(row) => row.id} />
                </Grid>
            </Grid>
        </>
    )
}
export default PickupsPending
