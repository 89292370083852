import React, { useEffect, useState } from "react";
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import { Chip, Grid, Button, Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetShipmentDetailQuery } from '../../../redux/commonRTK';
import {
  getOrderLists,
  syncOrder,
} from "../../../redux/actions/orderList/orderListAction";
import { apiConstants, get, getGeneric, postGeneric } from "../../../common/constants";
import { resetOrderList } from "../../../redux/reducers/orderList/orderListSlice";
import Loader from "../../../common/Loader";
import {
  parseDateAndTime,
  getTotalUnitsOfOrder,
  shouldMaskData,
  maskAddress,
  formatCurrency,
  formatDateForApi,
} from "../../../helpers/UtilityHelper";
import { exclude, ItemListModal } from "./index";
import { Link } from "react-router-dom";
import MUIModal from "../../../common/MUIModal";
/* import CreateIcon from "@mui/icons-material/Create"; */
import Remarks from "../../Remarks/Remarks";
// import ConfirmDialog from "../../../common/ConfirmDialog";
import { useSnackbar } from "notistack";
import { FormProvider, RHFRadioGroup, RHFSelectField } from "../../../common/hook-form";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import PageTitle from "../../../common/PageTitle";
import { useGetCouriersQuery } from "../../../redux/commonRTKPersist";
import _ from 'lodash'
import { addDays, format } from "date-fns";
import ConfirmDialog from '../../../common/ConfirmDialog';

const AllOrders = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { orderListInfo, loading } = useSelector(
    (state) => state.orderListSlice
  );
  const { loading: orderSummaryLoading } = useSelector(
    (state) => state.orderSummarySlice
  );
  const [page, setPage] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [getShipmentDetail] = useLazyGetShipmentDetailQuery();
  // const { fromDate: from_date, toDate: to_date } = getDashboardDateFilter('last_30_days')
  const [itemModal, setItemModal] = useState(false);
  const [itemModalData, setItemModalData] = useState();
  const [open, setOpen] = useState(false);
  const [clone, setClone] = useState(false);
  const [id, setId] = useState();
  const [reload, setReload] = useState(0);
  const paymentMode = [{ label: "PREPAID", value: "PREPAID" }, { label: "COD", value: "COD" }]
  const methods = useForm({
    defaultValues: {
      payment_mode: '',
      courier_type: ''
    }
  });
  const { handleSubmit, setValue, watch, reset: resetClone } = methods;
  const [data, setData] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { report } = useSelector(state => state);
  const courierSelection = watch('courier_type')
  const [courierData, setCourierData] = useState()
  const [newCourier, setNewCourier] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [waybillNum, setWaybill] = useState();
  const { data: allCouriers } = useGetCouriersQuery()

  useEffect(() => {
    const andfilter = [];
    const orFilter = [];
    if (props.filters.status) {
      andfilter.push({
        field: "orderStatus",
        operator: "in",
        value: [props.filters.status],
      });
    } else {
      andfilter.push({
        field: "orderSubStatus",
        operator: "ne",
        value: "UNPROCESSED",
      });
    }
    if (props.shipmentType === 'B2C') {
      andfilter.push({ field: "orderType", operator: "eq", value: 'FORWARD SHIPMENT' });
    }
    andfilter.push({ field: "shipmentType", operator: "eq", value: props.shipmentType });

    // if (!(props.filters.fromDate && props.filters.toDate)) {
    //   andfilter.push({ field: "awbRegisteredDate", operator: "gte", value: props.startDateForLastThirtyDays})
    // }


    if (props.merchantId) {
      andfilter.push({
        field: "merchant.$id",
        operator: "eq",
        value: props.merchantId,
      });
    }
    if (props.filters) {
      if (props.filters.awb) {
        andfilter.push({
          field: "awbNumber",
          operator: "eq",
          value: props.filters.awb,
        });
      }
      if (props.filters.payment_mode) {
        andfilter.push({
          field: "paymentMode",
          operator: "eq",
          value: props.filters.payment_mode,
        });
      }
      if (props.filters.city) {
        andfilter.push({
          field: "deliveryDetails.to_city",
          operator: "eq",
          value: props.filters.city,
        });
      }
      if (props.filters.state) {
        andfilter.push({
          field: "deliveryDetails.to_state",
          operator: "eq",
          value: props.filters.state,
        });
      }
      if (props.filters.fromDate) {
        andfilter.push({
          field: "awbRegisteredDate",
          operator: "gte",
          // value: formatDate(props.filters.fromDate, "yyyy-MM-dd"),
          value: formatDateForApi(props.filters.fromDate),
        });
      }
      if (props.filters.toDate) {
        andfilter.push({
          field: "awbRegisteredDate",
          operator: "lte",
          // value: formatDate(props.filters.toDate, "yyyy-MM-dd"),
          value: formatDateForApi(props.filters.toDate),
        });
      }
      if (props.filters.email) {
        andfilter.push({
          field: "deliveryDetails.to_email",
          operator: "eq",
          value: props.filters.email,
        });
      }
      if (props.filters.phone) {
        andfilter.push({
          field: "deliveryDetails.to_phone_number",
          operator: "eq",
          value: props.filters.phone,
        });
      }
    }

    if (props.filters) {
      if (props.filters.reference) {
        orFilter.push({
          field: "reference",
          operator: "eq",
          value: props.filters.reference,
        });
      }
      if (props.filters.reference) {
        orFilter.push({
          field: "shippingLabelId",
          operator: "eq",
          value: props.filters.reference,
        });
      }

    }
    dispatch(resetOrderList());
    dispatch(
      getOrderLists({
        excludes: exclude,
        andfilter: andfilter,
        orfilter: orFilter,
        offset: page * sizePerPage,
        limit: sizePerPage,
        skipCount: true,
        "sort": { "field": "awbRegisteredDate", "direction": "DESC" },
      })
    );
    // setRowCountState(props?.count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sizePerPage, props.filterChange, reload]);

  const handleSync = (awbNumber, reference) => {
    if (awbNumber) {
      dispatch(syncOrder({ waybill: awbNumber, reference: reference }));
      // console.log(orderListInfo);
    }
  };

  const cloneOrder = (fill) => {
    // getShipmentDetail({
    //   "excludes": "sla,mongoLabelSignedUrlDetails,orderManifest,courierPricing,mongoInvoiceSignedUrlDetails,courierDetail,courier,courierId,merchant,merchantPricing,order_history", andfilter: [{ "field": "awbNumber", "operator": "eq", "value": id }], "offset": 0, "limit": 1
    // }).then((res) => {
    //   let data = res.data.data[0];
    setIsSubmitting(true)
    let newData = {}
    newData.order_type = data.order_type;
    newData.order_date = new Date();
    newData.item_list = data.item_list;
    newData.pickup_details = data.pickup_details;
    newData.delivery_details = data.delivery_details;
    newData.shipment_type = data.shipment_type;
    newData.customer_detail = data.customer_detail;
    newData.shipment_detail = data.shipment_detail;
    newData.invoice_value = data.invoice_value;
    newData.payment_mode = fill.payment_mode;
    newData.cod_amount = fill.payment_mode === "PREPAID" ? 0 : data.invoice_value;
    newData.client_order_id = data.client_order_id;
    newData.invoice_number = data.invoice_number;
    newData.shipping_charge = data.shipping_charge;
    newData.transaction_charge = data.transaction_charge;
    newData.giftwrap_charge = data.giftwrap_charge;
    newData.shippingLabelId = data.shippingLabelId;
    newData.reference = 'c' + data.reference;
    newData.channelOrderUniqueKey = data.channelOrderUniqueKey;
    newData.dispatch_mode = data.dispatch_mode;
    newData.skipRule = fill.courier_type === "Other" ? true : false;
    newData.merchantOId = props.merchantId
    if (fill.courier_type !== "Other") {
      delete newData.courierPartner;
    } else {
      newData.courier_id = {
        prozoId: fill.courierPartner
      };
      delete newData.courierPartner;
    }

    try {
      postGeneric(apiConstants.CREATE_ORDER, newData).then((res) => {
        if (res.status === 200) {
          enqueueSnackbar(res?.data?.meta?.message || "Order cloned successfully", { variant: "success" });
          setClone(() => false)
          setIsSubmitting(false)
        }
      }).catch((err) => {
        enqueueSnackbar("Something went wrong while cloning the order", { variant: "error" })
        setIsSubmitting(false)
        setClone(() => false)
      })
    } catch (err) {
      enqueueSnackbar("Something went wrong while cloning the order", { variant: "error" });
      setClone(() => false)
      setIsSubmitting(false)
    }
  };

  const handleClickOpen = (row) => {
    setOpen(true);
    setId(row);
    // console.log(artifactId)
  };
  const handleClickClone = (row, data) => {
    setCourierData(data)
    setClone(true);
    setId(row);
  };

  useEffect(() => {
    if (id && clone) {
      getShipmentDetail({
        "excludes": "sla,mongoLabelSignedUrlDetails,orderManifest,courierPricing,mongoInvoiceSignedUrlDetails,courierDetail,courier,courierId,merchant,merchantPricing,order_history", andfilter: [{ "field": "awbNumber", "operator": "eq", "value": id }], "offset": 0, "limit": 1
      }).then((res) => {
        let data = res.data.data[0]
        setData(data)
        setValue("payment_mode", data.payment_mode)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, clone])

  const [fetchLabel, setFetchLabel] = useState(false)

  const handleFetchLabel = (row) => {
    setFetchLabel(true)
    getGeneric(`${apiConstants.FETCH_CUSTOM_LABEL}?orderReference=${row?.reference}`).then((res) => {
      setFetchLabel(false)
      if (res?.data?.labelUrl) {
        enqueueSnackbar("Label Generated Successfully", { variant: "success" });
        setReload(reload => reload + 1)
      }
    }).catch((err) => {
      setFetchLabel(false)
      console.log(err);
      enqueueSnackbar("Some Error Occurred", { variant: "error" });
    })
  };

  const columns = [
    {
      field: "actions",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Order Details",
      flex: 1.3,
      renderCell: (params) => {
        const { row } = params;
        return (
          <>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                {row.reference}
              </Grid>
              {row.shippingLabelId && row.reference !== row.shippingLabelId && (
                <Grid item xs={12}>
                  {row.shippingLabelId}
                </Grid>
              )}
              <Grid item xs={12}>
                {row.createdDate ? parseDateAndTime(row.createdDate) : ""}
              </Grid>
              {row.channel_name &&
                row.channel_name !== "Unicom" &&
                row.channel_name !== "WMS" && (
                  <Grid item xs={12}>
                    {row.channel_name ? row.channel_name : ""}
                  </Grid>
                )}
            </Grid>
          </>
        );
      },
    },
    {
      field: "courier",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Courier Partner",
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        let courierParent = row.courierDetail?.parent?.includes('Blended') ? row.actualCourierProviderName : row.courierDetail?.parent;
        return (
          <>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                AWB: <b>{(row.awb_number) ? row.awb_number : ""}</b>
              </Grid>
              <Grid item xs={12}>
                Courier : {courierParent}<br />
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      field: "delivery",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Delivery Details",
      flex: 1.5,
      renderCell: (params) => {
        const { row } = params;
        return (
          <>
            <div className="col-12 col-md position-relative mt-4 mt-md-0">
              <div className="row g-0">
                <div className="col-6 col-md-12 d-flex delivery-location flex-column flex-md-row">
                  <div className="flex-shrink-0">
                    {" "}
                    <img src="img/location.svg" height="17" alt="" />{" "}
                  </div>
                  <div className="flex-grow-1 ms-md-3 mt-2 mt-md-0">
                    {row.pickup_details && row.pickup_details.from_name
                      ? row.pickup_details.from_name
                      : ""}
                    .<br />
                    {row.pickup_details && row.pickup_details.from_pincode
                      ? row.pickup_details.from_pincode
                      : ""}
                  </div>
                </div>
                <div className="col-6 col-md-12 d-flex delivery-location flex-column flex-md-row">
                  <div className="flex-shrink-0 text-end text-md-start">
                    {" "}
                    <img src="img/location.svg" height="17" alt="" />{" "}
                  </div>
                  <div className="flex-grow-1 ms-md-3 d-flex justify-content-end justify-content-md-start mt-2 mt-md-0">
                    {row.delivery_details && row.delivery_details.to_name
                      ? row.delivery_details.to_name
                      : ""}
                    <br />
                    {/* {row.delivery_details && row.delivery_details.to_pincode
                      ? row.delivery_details.to_pincode
                      : ""} */}
                    {(row.delivery_details && row.delivery_details.to_pincode) ?
                      (shouldMaskData(report, row) ?
                        maskAddress(row.delivery_details.to_pincode) :
                        row.delivery_details.to_pincode) :
                      ""}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: "shipment",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Package Details",
      flex: 1.3,
      renderCell: (params) => {
        const { row } = params;
        return (
          <>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                Dead Weight :{" "}
                {row.shipment_detail && row.shipment_detail[0]
                  ? `${(row.shipment_detail[0].item_weight / 1000).toFixed(
                    2
                  )}kg`
                  : ""}
              </Grid>
              {row.shipment_type !== 'B2B' && (
                <Grid item xs={12}>
                  Vol Weight :{" "}
                  {row.shipment_detail && row.shipment_detail[0]
                    ? `${(
                      (row.shipment_detail[0].item_length *
                        row.shipment_detail[0].item_breadth *
                        row.shipment_detail[0].item_height) /
                      5000
                    ).toFixed(2)}kg`
                    : ""}
                </Grid>
              )}
              {row.shipment_type !== 'B2B' && (
                <Grid item xs={12}>
                  Dimensions :{" "}
                  {row.shipment_detail && row.shipment_detail[0]
                    ? `${row.shipment_detail[0].item_length} x ${row.shipment_detail[0].item_breadth} x ${row.shipment_detail[0].item_height} cm`
                    : ""}
                </Grid>
              )}
            </Grid>
          </>
        );
      },
    },
    {
      field: "itemDetails",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Item Details",
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return (
          <>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    setItemModalData(row.item_list);
                    setItemModal(true);
                  }}
                >
                  Items
                </Button>
              </Grid>
              <Grid item xs={12}>
                Total Units : {getTotalUnitsOfOrder(row.item_list)}
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      field: "paymentDetails",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Payment Details",
      flex: 0.7,
      renderCell: (params) => {
        const { row } = params;
        const paymentMode = row.payment_mode ? row.payment_mode : "";
        const color = paymentMode === "COD" ? "error" : "success";
        return (
          <>
            <PageTitle>All Shipments</PageTitle>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                {row.invoice_value ? "Rs. " + row.invoice_value : ""}
              </Grid>
              <Grid item xs={12}>
                <Chip size="small" color={color} label={paymentMode} />
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      field: "statusDetails",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Status",
      flex: 1.2,
      renderCell: (params) => {
        const { row } = params;
        return <Chip variant="filled" label={row.orderStatus} />;
      },
    },
    {
      field: "action",
      headerAlign: "center",
      hideSortIcons: true,
      headerName: "Action",
      flex: 1.4,
      renderCell: (params) => {
        const { row } = params;
        return (
          <Box sx={{ mt: 1, mb: 1 }}>
            <Grid container rowSpacing={1}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="small"
                  color="warning"
                  component={Link}
                  to={`/order-detail?waybill=${row.awb_number}`}
                >
                  Details
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="small"
                  color="warning"
                  onClick={() => handleClickClone(row.awb_number, row)}
                // component={Link}
                // to={`/order-clone-detail?waybill=${row.awb_number}`}
                >
                  Clone
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="small"
                  color="warning"
                  onClick={() => handleSync(row.awb_number, row?.reference)}
                >
                  Resync
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="small"
                  color="warning"
                  onClick={() => handleClickOpen(row.id)}
                >
                  Remark
                </Button>
              </Grid>
              {
                (
                  ((row?.shipment_type === "B2B" && row?.courierDetail?.parent === "Delhivery" && !row?.label_url) &&
                    (row?.orderStatus === "AWB_REGISTERED" || row?.orderStatus === "PICKUP_PENDING" || row?.orderStatus === "ORDER_PLACED"
                    ))) ?
                  (
                    <Grid item xs={8}>
                      <Button
                        variant="contained"
                        size="small"
                        color="warning"
                        onClick={() => handleFetchLabel(row)}
                      >
                        Fetch Label
                      </Button>
                    </Grid>
                  ) : ''
              }
              <Grid item xs={6}>
                <Button variant="contained"
                  size="small" color="error"
                  onClick={() => { toggleCancel(row.awb_number) }}
                  disabled={row?.orderStatus === "CANCELLED_ORDER" || row?.orderStatus === "DELIVERED" || row?.orderStatus === "FAILED_DELIVERY"}>
                  Cancel</Button>
              </Grid>
            </Grid>
          </Box>
        );
      },
    },
  ];

  const toggleCancel = (val) => {
    setCancelConfirm(true);
    setWaybill(val)
  };

  // useEffect(() => {
  //   setRowCountState((prevRowCountState) =>
  //     props?.count !== undefined ? props?.count : prevRowCountState
  //   );
  // }, [props?.count, setRowCountState]);

  useEffect(() => {
    if (courierData && courierSelection === "Other") {
      setIsLoading(true)
      const pickup_details = {
        from_address: courierData?.pickup_details?.address_line,
        from_city: courierData?.pickup_details?.city,
        from_country: courierData?.pickup_details?.country,
        from_name: courierData?.pickup_details?.name,
        from_pincode: courierData?.pickup_details?.from_pincode,
        from_state: courierData?.pickup_details?.state,
        from_phone_number: courierData?.pickup_details?.contactNumber,
        from_email: courierData?.pickup_details?.email,
        gstin: courierData?.pickup_details?.gstin
      }

      let length = _.sumBy(courierData?.shipment_detail, "item_length")
      let breadth = _.sumBy(courierData?.shipment_detail, "item_breadth")
      let height = _.sumBy(courierData?.shipment_detail, "item_height")
      let weight = _.sumBy(courierData?.shipment_detail, "item_weight")
      if (pickup_details) {
        postGeneric(`${apiConstants.SHIPMENT_RATE_CALCULATOR}`, {
          orderType: "FORWARD",
          to: parseInt(courierData?.delivery_details?.to_pincode),
          from: parseInt(pickup_details?.from_pincode),
          paymentType: courierData?.payment_mode.toLowerCase() === "prepaid" ? "PREPAID" : "COD",
          "length": length,
          "breadth": breadth,
          "height": height,
          "weight": weight,
          "dispatchMode": "SURFACE",
          "codAmount": Number(courierData.cod_amount) || 0,
          "isMerchant": true,
          onlyCouriersAsPerRuleMapping: true,
          merchantOid: props.merchantId
        }).then((res) => {
          setIsLoading(false)
          const pricing = res?.data;
          const newCourier = allCouriers?.map((d) => {
            const updatedCourier = { ...d };
            pricing.forEach((t) => {
              if (t.provider?.id === updatedCourier.prozoId) {
                updatedCourier.priceAfterTax = t.priceAfterTax;
                updatedCourier.providerMinTatDays = t.providerMinTatDays;
                updatedCourier.providerMaxTatDays = t.providerMaxTatDays;
              }
            });
            return updatedCourier;
          })
          let filteredCouriers = newCourier.filter(item =>
            item.priceAfterTax &&
            item.type === 'FORWARD'
          )
          const now = new Date();
          filteredCouriers.forEach(shipment => {
            let minTatDays = shipment.providerMinTatDays || 0;
            let maxTatDays = shipment.providerMaxTatDays || 0;
            shipment.minDeliveryDate = format(addDays(now, minTatDays), 'dd, MMM');
            shipment.maxDeliveryDate = format(addDays(now, maxTatDays), 'dd, MMM');
          });
          setNewCourier(filteredCouriers);
        }).catch((err) => {
          console.log(err)
          enqueueSnackbar(err?.response?.data?.message || "Something went wrong", { variant: "error" });
          setIsLoading(false)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courierSelection, courierData])

  const cancelOrder = () => {
    // setLoading(true);
    get(`${apiConstants.BASE_LOCAL}${apiConstants.CANCEL_ORDER}?waybill=${waybillNum}`).then(response => {
      // setLoading(false);
      let rs = response.data;
      if (rs.meta && rs.meta.status && rs.meta.status === "200") {
        enqueueSnackbar("Cancelled successfully", { variant: 'success' });
        setReload(reload + 1);
      }
      if (rs.meta && rs.meta.status && rs.meta.status === "350") {
        enqueueSnackbar("Unable to cancel with courier partner at this point", { variant: 'warning' });
      }
      else if (rs.meta && rs.meta.status && rs.meta.status === "603") {
        enqueueSnackbar("Courier partner API cancellation not supported", { variant: 'error' });
      }
      else if (rs.meta && rs.meta.status && rs.meta.status !== "200" && rs.meta.status !== "350" && rs.meta.status !== "603") {
        enqueueSnackbar(rs.meta.message, { variant: 'error' });
      }
      // dispatch(getOrderLists({ "andfilter": andfilter, "offset": 0, "limit": 1 }));
    })
      .catch(err => {
        console.error(err);
      });
  }
  return (
    <>
      <ConfirmDialog
        title=''
        open={cancelConfirm}
        setOpen={setCancelConfirm}
        onConfirm={() => cancelOrder()}
      >
        Are you sure you want to cancel this order ?
      </ConfirmDialog>
      <Grid item container spacing={2}>
        {(loading || orderSummaryLoading || fetchLabel) && <Loader />}
        <ItemListModal data={itemModalData} itemModal={itemModal} setItemModal={setItemModal} />
        <Grid item xs={12}>
          <ProzoDataGrid
            columns={columns}
            // disableColumnFilter={true}rowsPerPageOptions
            autoHeight={true}
            rows={
              orderListInfo.data && orderListInfo.data.length > 0
                ? orderListInfo.data
                : []
            }
            sizePerPage={sizePerPage}
            setSizePerPage={setSizePerPage}
            setPage={setPage}
            page={page}
            pagination={true}
            hideFooterPagination={false}
            filterMode={"server"}
            rowHeight={180}
            // rowCount={rowCountState || 0}
            rowCount={Number.MAX_VALUE}
            getRowId={(row) => row.id}
          />
        </Grid>
      </Grid>

      <MUIModal
        open={open}
        handleClose={() => setOpen(false)}
        title={"Add Remarks"}
        hideClose={true}
      > <Remarks
          id={id}
          on={"ORDER"}
          setReload={setReload}
          reload={reload}
          setOpen={setOpen}
        />
      </MUIModal>

      {/* <ConfirmDialog
        title='Are you sure you want to clone this order?'
        open={clone}
        setOpen={setClone}
        onConfirm={() => cloneOrder()}>
        Are you sure you want to clone this order?
        <FormProvider methods={methods} onSubmit={handleSubmit(cloneOrder)}>
          <RHFSelectField name='payment_mode' label='Payment Mode' options={paymentMode.map((val) => ({
            value: val.value,
            label: val.label
          }))} />
        </FormProvider>
      </ConfirmDialog> */}
      <MUIModal
        maxWidth="sm"
        open={clone}
        handleClose={() => { setClone(false); resetClone() }}
        title={"Are you sure you want to clone this order?"}
        hideClose={true}
      // sx={{ width: '100%', justifyContent: 'center' }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(cloneOrder)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RHFSelectField name='payment_mode' width={"100%"} label='Payment Mode' options={paymentMode.map((val) => ({
                value: val.value,
                label: val.label
              }))} />
            </Grid>
            <Grid item xs={12} sx={{ mt: 1, ml: 1 }}>
              <RHFRadioGroup label="Courier Selection" name="courier_type" options={[
                {
                  label: "As per Recommendation Rules",
                  value: "Prozo Choice",
                },
                { label: "Other Courier", value: "Other" },
              ]}

              />
            </Grid>
            {isLoading ? <Loader /> :
              <>
                {courierSelection === 'Other' && (
                  <Grid item xs={12}>
                    <RHFSelectField
                      variant="filled"
                      width="100%"
                      name="courierPartner"
                      label="Courier Partner"
                      noOptionsText='No courier partner available for this pickup location'
                      options={(newCourier)?.filter(row => !row.isExternal).map((c) => {
                        return {
                          label: <Grid container spacing={0}>
                            <Grid item xs={3}>
                              <Typography variant="body2">{c?.name}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography variant="body2">{formatCurrency(c?.priceAfterTax)}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography variant="body2">
                                {c.providerMinTatDays !== undefined && c.providerMaxTatDays !== undefined && c.providerMaxTatDays > 0 ?
                                  `TAT: ${c.providerMinTatDays}-${c.providerMaxTatDays} Days` :
                                  'TAT: Not Available'}
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography variant="body2">
                                {c.providerMinTatDays !== undefined && c.providerMaxTatDays !== undefined && c.providerMaxTatDays > 0 ?
                                  `EDD: ${c.minDeliveryDate}-${c.maxDeliveryDate}` :
                                  'EDD: Not Available'}
                              </Typography>
                            </Grid>
                          </Grid>,
                          value: c?.prozoId
                        };
                      })}
                    />
                  </Grid>
                )}
              </>
            }
            <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', p: 2 }}>
              <Button variant="contained" color="error" onClick={() => setClone(false)}>No</Button>
              <LoadingButton loading={isSubmitting} variant="contained" color="success" type='submit' sx={{ ml: 1 }}>YES</LoadingButton>
            </Grid>
          </Grid>
        </FormProvider>
      </MUIModal>
    </>
  );
};

export default AllOrders;
