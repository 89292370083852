import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ErrorBoundary from "../../../../common/ErrorBoundary";
import { TableSkeleton } from "../../../../common/Skeletons";
import useStuckShipments from "./useStuckShipment";
import CourierStatusWiseSplit from "./CourierStatusWiseSplit";
import AgeingSplit from "./AgeingSplit";
import ExportChartDataToExcel from "../../../../common/ExportChartDataToExcel";
import { parse } from "date-fns";
import _ from "lodash";
import { getDDMMYYYYHHmmToIST } from "../../../../helpers/UtilityHelper";
import { useGetAllCourierPartnersQuery } from "../../../../redux/commonRTK";

const hours = ['24', '48', '72', '96', '120', '144'];

function StuckComponent() {

    const [selectedHour, setSelectedHour] = React.useState('24');
    const requestPayload = {
        andfilter: [{ "field": "is_deleted", "operator": "eq", "value": '0' }],
        offset: 0,
        limit: 500,
        includes: "id,name,parent,prozoId"
    }
    const { data: allCouriers } = useGetAllCourierPartnersQuery(requestPayload);    
    const { dataGroupedByAgeing, dataGroupedByCourier, dataGroupedByStatus, uniqCourierNames, isLoading, rawData } = useStuckShipments(selectedHour);
    const couriersGroupedById = _.groupBy(allCouriers, 'id');

    const handleHourChange = (_e, newValue) => {
        if (newValue !== null) {
            setSelectedHour(newValue)
        }
    }
    
    const downloadOptions = [
        {
            label: 'Download All orders',
            fileName: 'All_ORDERS',
            data: rawData || [],
            columns: [
                { label: 'Order Number', value: 'orderId' },
                { label: 'AWB Number', value: 'awbNumber' },
                {
                    label: 'Courier', value: (row) => {
                        const courier = couriersGroupedById[row.courier][0];
                        const courierName = courier ? (courier.parent || courier.name) : undefined;
                        return courierName;
                    }
                },
                { label: 'Current Status', value: 'orderStatus' },
                { label: 'AWB Date', value: (row) => getDDMMYYYYHHmmToIST(parse(row.awbRegisteredDate, "dd-MM-yyyy HH:mm", new Date())) },
                { label: 'Last Update Date', value: (row) => getDDMMYYYYHHmmToIST(parse(row.lastStatusUpdateTime, "dd-MM-yyyy HH:mm", new Date())) }
            ]
        }
    ]

    return (
        <Accordion expanded>
            <AccordionSummary>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h5">Stuck Shipments</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <ExportChartDataToExcel options={downloadOptions} />
                            <ToggleButtonGroup
                                exclusive
                                onChange={handleHourChange}
                                value={selectedHour}
                                size="small"
                                color="error"
                                sx={{
                                    mr: 1,
                                    '& .MuiToggleButton-root': {
                                        fontWeight: 'bold',
                                    }
                                }}>
                                <ToggleButton disabled>Hours</ToggleButton>
                                {hours.map(hour => <ToggleButton key={hour} value={hour}>{hour}</ToggleButton>)}
                            </ToggleButtonGroup>
                        </Box>
                    </Box>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        The data below provides an overall summary of all stuck shipments. Stuck shipments are shipments where there the status of the shipment has not been updated for a considerable period of time. All data is populated on basis of the date of shipment creation in Proship.
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#f5f8fa' }}>
                <Grid container spacing={2} alignItems="center">
                    {isLoading && (
                        <Grid item xs={12}>
                            <TableSkeleton rows={10} />
                        </Grid>
                    )}
                    {dataGroupedByCourier && (
                        <Grid item xs={12}>
                            <CourierStatusWiseSplit isCourierView={true} data={dataGroupedByCourier} title="Courier Wise Split" />
                        </Grid>
                    )}
                    {dataGroupedByStatus && (
                        <Grid item xs={12}>
                            <CourierStatusWiseSplit data={dataGroupedByStatus} title="Status Wise Split" />
                        </Grid>
                    )}
                    {dataGroupedByAgeing && (
                        <Grid item xs={12}>
                            <AgeingSplit data={dataGroupedByAgeing} uniqCourierNames={uniqCourierNames} />
                        </Grid>
                    )}
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default function Stuck() {
    return (
        <ErrorBoundary>
            <StuckComponent />
        </ErrorBoundary>
    )
}